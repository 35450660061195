export const MUSIC_CREATE_REQUEST = "MUSIC_CREATE_REQUEST";
export const MUSIC_CREATE_SUCCESS = "MUSIC_CREATE_SUCCESS";
export const MUSIC_CREATE_FAILURE = "MUSIC_CREATE_FAILURE";

export const MUSIC_LIST_REQUEST = "MUSIC_LIST_REQUEST";
export const MUSIC_LIST_SUCCESS = "MUSIC_LIST_SUCCESS";
export const MUSIC_LIST_FAILURE = "MUSIC_LIST_FAILURE";

export const MUSIC_DELETE_REQUEST = "MUSIC_DELETE_REQUEST";
export const MUSIC_DELETE_SUCCESS = "MUSIC_DELETE_SUCCESS";
export const MUSIC_DELETE_FAILURE = "MUSIC_DELETE_FAILURE";

export const MUSIC_UPDATE_REQUEST = "MUSIC_UPDATE_REQUEST";
export const MUSIC_UPDATE_SUCCESS = "MUSIC_UPDATE_SUCCESS";
export const MUSIC_UPDATE_FAILURE = "MUSIC_UPDATE_FAILURE";

export const MUSIC_EDIT_REQUEST = "MUSIC_EDIT_REQUEST";
export const MUSIC_EDIT_SUCCESS = "MUSIC_EDIT_SUCCESS";
export const MUSIC_EDIT_FAILURE = "MUSIC_EDIT_FAILURE";
