import React from "react";

const EmptyList = ({ title }) => {
  return (
    <div className="max-w-7xl mx-auto my-5">
      <div className="flex items-center justify-center">
        <img
          style={{ width: "100%", height: "300px", objectFit: "contain" }}
          src="/assets/images/not-found.png"
          alt="Not-found"
        />
      </div>
      <h4 className="text-center text-2xl my-10 text-gray-500 italic">
        {title}
      </h4>
    </div>
  );
};

export default EmptyList;
