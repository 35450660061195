import React from "react";
import Gallery from "../../core/components/Gallery";

export default function GalleryPage() {
  return (
    <div className="max-w-6xl mx-auto md:px-4 py-4">
      <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 py-6">
        Gallerie
      </h1>
      <Gallery />
    </div>
  );
}
