import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RefreshIcon } from "@heroicons/react/outline";
import { useFlash } from "../../core/contexts/FlashProvider";
import { useApi } from "../../core/contexts/ApiProvider";

// form validation rules
const SigninSchema = Yup.object().shape({
  password: Yup.string().required("Champs requis"),
  confirm_password: Yup.string()
    .required("Champs requis")
    .oneOf([Yup.ref("password"), null], "Les mots de passe ne correspondent pas!"),
});

const ResetPassPage = () => {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  let { token } = useParams();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: SigninSchema,
    onSubmit: async (values) => {
      const response = await api.post(`/api/auth/reset-password`, {
        ...values,
        token,
      });
      if (response.success) {
        navigate(`/`);
      } else {
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <h1 className="text-2xl">Changer de mot de passe</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-2">
            <label>Mot de passe</label>
            <input
              className="input"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password ? (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <label>Confirmer le mot de passe</label>
            <input
              className="input"
              type="password"
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
            />
            {formik.touched.confirm_password &&
            formik.errors.confirm_password ? (
              <span className="text-red-500 text-sm">
                {formik.errors.confirm_password}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <button className="btn" type="submit">
              {formik.isSubmitting ? (
                <RefreshIcon
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                />
              ) : (
                "Enregistrer"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassPage;
