import React from "react";
import { useNavigate, useParams } from "react-router";
import { useApi } from "../../core/contexts/ApiProvider";
import { useFlash } from "../../core/contexts/FlashProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RefreshIcon } from "@heroicons/react/outline";

// form validation rules
const OtpSchema = Yup.object().shape({
  activation_code: Yup.string().required("Champs requis"),
});

export default function Otp() {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  let { token } = useParams();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      code: "+225",
      telephone: "",
      email: "",
      password: "",
    },
    validationSchema: OtpSchema,
    onSubmit: async (values) => {
      const { activation_code } = values;
      const response = await api.post("/api/auth/otp-verify", {
        activation_token: token,
        activation_code,
      });
      if (response.success) {
        flash(`${response.message}`, "success");
        navigate(`/login`);
      } else {
        console.log(response);
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <h1 className="text-2xl">Verification de votre email</h1>
        <p>Un code vous a ete envoye dans votre boite mail renseigner le</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-2">
            <label>Code</label>
            <input
              className="input"
              type="text"
              name="activation_code"
              onChange={formik.handleChange}
              value={formik.values.activation_code}
            />
            {formik.errors.activation_code && formik.touched.activation_code ? (
              <div className="text-danger">{formik.errors.activation_code}</div>
            ) : null}
          </div>
          <div className="my-2">
            <button className="btn" type="submit">
              {formik.isSubmitting ? (
                <RefreshIcon
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                />
              ) : (
                "Envoyer"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
