import React, { useState } from "react";
import Avatar from "../../core/components/Avatar";
import { useApi } from "../../core/contexts/ApiProvider";
import { useFlash } from "../../core/contexts/FlashProvider";
import { useUser } from "../../core/contexts/UserProvider";
import { formatTimeAgo } from "../../core/helpers/utility";
import { TrashIcon } from "@heroicons/react/outline";

export default function Comments(props) {
  const api = useApi();
  const flash = useFlash();
  const { user } = useUser();
  const [text, setText] = useState("");

  const addComment = async (event) => {
    if (event.keyCode == 13 && event.target.value) {
      event.preventDefault();
      const response = await api.put(`/api/feeds/comment/${props?.feedId}`, {
        text: text,
      });
      if (response.success) {
        setText("");
        props.updateComments(response.data.comments);
      }
    }
  };

  const deleteComment = async (comment) => {
    const response = await api.put(`/api/feeds/uncomment/${props?.feedId}`, {
      comment: comment,
    });
    if (response.success) {
      setText("");
      props.updateComments(response.data.comments);
    }
  };

  return (
    <>
      <div className="flex items-center gap-3 w-full my-[32px]">
        <div>
          <Avatar img={user?.image?.name} text={user?.firstName} size={40} />
        </div>
        <div className="flex-grow">
          <input
            type="text"
            className="w-full rounded"
            placeholder="Ecrivez quelques choses ..."
            onKeyDown={addComment}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>
      {props.comments.map((item, i) => {
        return (
          <div className="flex items-start gap-4 my-4">
            <Avatar
              img={item.postedBy.image?.name}
              text={item.postedBy.firstName}
              size={40}
            />
            <div>
              <div className="flex items-center gap-2">
                <span className="font-bold">
                  {item.postedBy.firstName + " " + item.postedBy.lastName}
                </span>
                <span className="text-gray-400">
                  {formatTimeAgo(item.postedBy.created)}
                </span>
              </div>
              <div>{item.text}</div>
            </div>
            <div className="flex-grow flex justify-end items-center">
            {user._id === item.postedBy._id && (
              <button onClick={() => deleteComment(item)}>
                <TrashIcon className="w-6 h-6" />
              </button>
            )}
            </div>
          </div>
        );
      })}
    </>
  );
}
