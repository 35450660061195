export const IMAGE_UPLOAD_REQUEST = "IMAGE_UPLOAD_REQUEST";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILURE = "IMAGE_UPLOAD_FAILURE";

export const AUDIO_UPLOAD_REQUEST = "AUDIO_UPLOAD_REQUEST";
export const AUDIO_UPLOAD_SUCCESS = "AUDIO_UPLOAD_SUCCESS";
export const AUDIO_UPLOAD_FAILURE = "AUDIO_UPLOAD_FAILURE";

export const VIDEO_UPLOAD_REQUEST = "VIDEO_UPLOAD_REQUEST";
export const VIDEO_UPLOAD_SUCCESS = "VIDEO_UPLOAD_SUCCESS";
export const VIDEO_UPLOAD_FAILURE = "VIDEO_UPLOAD_FAILURE";
