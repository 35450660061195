import React from "react";
import Feed from "./Feed";

import EmptyList from "../../components/EmptyList";

export default function FeedList(props) {
  console.log(props?.feeds?.length);
  return (
    <>
      {props?.feeds && props?.feeds?.length > 0 && (
        <div
          className="flex flex-col flex-col-reverse"
          style={{ marginTop: "24px" }}
        >
          {props?.feeds?.map((item, i) => {
            return <Feed feed={item} key={i} onRemove={props.removeUpdate} />;
          })}
        </div>
      )}

      {props?.feeds?.length === 0 && <EmptyList />}
    </>
  );
}
