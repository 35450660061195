import {
  NewspaperIcon,
  ArchiveIcon,
  LogoutIcon,
  UserIcon,
  VideoCameraIcon,
  RssIcon,
  UsersIcon,
} from "@heroicons/react/outline";

import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import logo from "../../logo.jpeg";

import ProfileImg from "../../images/profile.png";
import MobileMenu from "./MobileMenu";
import { useUser } from "../contexts/UserProvider";

const ShellLayout = () => {
  const { user, logout } = useUser();

  return (
    <div className="flex">
      <div className="hidden md:flex flex-col justify-between p-2 bg-gray-100 sticky top-0 h-screen max-w-[100px]">
        <div>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-4"
                : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-4"
            }
            to="/"
          >
            <img
              src={logo}
              alt=""
              className="w-8 h-8 rounded-full object-contain"
            />
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
            }
            to="/feeds"
          >
            <RssIcon className="w-6 h-6" />
            <span className="text-xs truncate">Actus.</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
            }
            to="/posts"
          >
            <ArchiveIcon className="w-6 h-6" />
            <span className="text-xs truncate">Articles</span>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
            }
            to="/gallery"
          >
            <VideoCameraIcon className="w-6 h-6" />
            <span className="text-xs truncate">Gallerie</span>
          </NavLink>
        </div>
        <div>
          {user ? (
            <>
              {user?.isAdmin && (
                <>
                  <NavLink
                    end
                    className={({ isActive }) =>
                      isActive
                        ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                        : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
                    }
                    to="/studio/posts"
                  >
                    <NewspaperIcon className="w-6 h-6" />
                    <span className="text-xs truncate">Mes Articles</span>
                  </NavLink>
                  <NavLink
                    end
                    className={({ isActive }) =>
                      isActive
                        ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                        : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
                    }
                    to="/studio/users"
                  >
                    <UsersIcon className="w-6 h-6" />
                    <span className="text-xs truncate">Mon equipe</span>
                  </NavLink>
                </>
              )}
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                    : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
                }
                to="/profile"
              >
                {user && user?.profilePicture ? (
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.profilePicture}
                    alt=""
                  />
                ) : (
                  <img
                    className="h-8 w-8 rounded-full"
                    src={ProfileImg}
                    alt=""
                  />
                )}
                <span className="text-xs truncate">Profile</span>
              </NavLink>
              <button
                className="hover:bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2 mx-auto"
                onClick={() => logout()}
              >
                <LogoutIcon className="w-6 h-6" />
                {/* <span className="text-xs">Déconnexion</span> */}
              </button>
            </>
          ) : (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-2"
                  : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-2"
              }
              to="/login"
            >
              <UserIcon className="w-6 h-6" />
              <span className="text-xs truncate">Login</span>
            </NavLink>
          )}
        </div>
      </div>
      <div className="w-full">
        <MobileMenu />
        <Outlet />
      </div>
    </div>
  );
};

export default ShellLayout;
