import React, { useEffect, useState } from "react";
import { useApi } from "../contexts/ApiProvider";
import { useUser } from "../contexts/UserProvider";
import FileViewer from "./FileViewer";
import { TrashIcon } from "@heroicons/react/outline";

export default function Gallery(props) {
  const { user } = useUser();
  const api = useApi();
  const [tab, setTab] = useState("image");
  const [files, setFiles] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/upload/me`);
      if (response.success) {
        setFiles(response.files);
      }
    })();
  }, []);

  const handleChange = async (event) => {
    const file = event.target.files[0];
    let formData = new FormData();
    formData.append("imageMsg", file);

    const response = await api.post(`/api/upload/upload-img`, formData);
    if (response.success) {
      const updatedFiles = [...files];
      updatedFiles.unshift(response.data);
      setFiles(updatedFiles);
    }
  };

  const removeFile = async (file) => {
    const response = await api.delete(`/api/upload/${file._id}`);
    if (response.success) {
      setFiles(files.map((f) => f._id !== file._id));
    }
  };

  return (
    <div className="text-center w-[800px]">
      <div className="flex items-center gap-4 py-2">
        {/* <button
          onClick={() => setTab("upload")}
          className={`${
            tab === "upload" ? "border-b-2 border-gray-500" : null
          } py-2`}
        >
          Upload
        </button> */}
        <button
          onClick={() => setTab("image")}
          className={`${
            tab === "image" ? "border-b-2 border-gray-500" : null
          } py-2`}
        >
          Images
        </button>
        <button
          onClick={() => setTab("video")}
          className={`${
            tab === "video" ? "border-b-2 border-gray-500" : null
          } py-2`}
        >
          Videos
        </button>
        <button
          onClick={() => setTab("audio")}
          className={`${
            tab === "audio" ? "border-b-2 border-gray-500" : null
          } py-2`}
        >
          Musics
        </button>
      </div>
      <div className="mx-auto my-4 w-full">
        {/* {tab === "upload" && (
          <label htmlFor="icon-button-file">
            <input
              accept="image/*"
              onChange={handleChange}
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
            />

            <div className="flex items-center justify-center mx-auto my-4 w-full h-48 border border-gray-400">
              <div className="">
                <h3 className="text-lg font-black text-gray-800">
                  Cliquer pour charger un fichier
                </h3>
                <p className="text-sm text-gray-500">
                  Taille maximum du fichier 16 MB
                </p>
              </div>
            </div>
          </label>
        )} */}
        {tab === "image" && (
          <div>
            {files && files.length > 0 && (
              <div className="grid grid-cols-4 gap-4">
                {files
                  .filter((f) => f.contentType.startsWith("image/"))
                  .map((file, index) => (
                    <>
                      {file && (
                        <div className="flex flex-col">
                          {user?.isAdmin && (
                            <button onClick={() => removeFile(file)}>
                              <TrashIcon className="w-6 h-6" />
                            </button>
                          )}
                          <FileViewer
                            fileUrl={`${process.env.REACT_APP_BACKEND_URL}/image/${file?.filename}`}
                            fileType={file?.contentType}
                          />
                          <h3>{file?.filename}</h3>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        )}
        {tab === "video" && (
          <div>
            {files && files.length > 0 && (
              <div className="grid grid-cols-4 gap-4">
                {files
                  .filter((f) => f.contentType.startsWith("video/"))
                  .map((file, index) => (
                    <>
                      {file && (
                        <div className="flex flex-col">
                          {user?.isAdmin && (
                            <button onClick={() => removeFile(file)}>
                              <TrashIcon className="w-6 h-6" />
                            </button>
                          )}
                          <FileViewer
                            fileUrl={`${process.env.REACT_APP_BACKEND_URL}/file/${file?.filename}`}
                            fileType={file?.contentType}
                          />
                          <h3>{file?.filename}</h3>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        )}
        {tab === "audio" && (
          <div>
            {files && files.length > 0 && (
              <div className="grid grid-cols-4 gap-4">
                {files
                  .filter((f) => f.contentType.startsWith("audio/"))
                  .map((file, index) => (
                    <>
                      {file && (
                        <div className="flex flex-col">
                          {user?.isAdmin && (
                            <button onClick={() => removeFile(file)}>
                              <TrashIcon className="w-6 h-6" />
                            </button>
                          )}
                          <FileViewer
                            fileUrl={`${process.env.REACT_APP_BACKEND_URL}/file/${file?.filename}`}
                            fileType={file?.contentType}
                          />
                          <h3>{file?.filename}</h3>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
