import React, { useEffect, useState } from "react";
import { GET_USER_POSTS } from "../../constants/apiEndpoints";
import { FetchWrapper } from "../../core/helpers/FetchWrapper";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { FiSearch, FiTrash2, FiEdit2 } from "react-icons/fi";
import { formatToDate } from "../../core/helpers/utility";
import Pagination from "../../core/components/common/Pagination";
import PostList from "../../components/studio/PostList";
import { useApi } from "../../core/contexts/ApiProvider";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import Categories from "./Categories";

export default function MyPostPage() {
  const api = useApi();
  const query = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams();

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/categories`);
      if (response.success) {
        setCategories(response.data);
      }
    })();
  }, []);

  const handleSetCategory = (category) => {
    let slug = "all";
    if (category) {
      slug = category.slug;
    }
    setCategory(category);
    setSearchParams({ tags: slug });
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex items-center justify-between border-b my-[40px]">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 py-6">
          Articles
        </h1>
        <Link className="px-4 py-2 border rounded-md" to="/studio/posts/new">
          Créer
        </Link>
      </div>

      <div>
        <div className="flex items-center gap-4 py-2">
          <button
            onClick={() => handleSetCategory(null)}
            className={`${
              category === null ? "border-b-2 border-gray-500" : null
            } py-2`}
          >
            Tous
          </button>
          {categories &&
            categories.map((cat, index) => {
              return (
                <button
                  key={index}
                  onClick={() => handleSetCategory(cat)}
                  className={`${
                    category?._id === cat?._id
                      ? "border-b-2 border-gray-500"
                      : null
                  } py-2`}
                >
                  {cat.name}
                </button>
              );
            })}
          <button onClick={() => setOpen(!open)}>
            <PlusCircleIcon className="w-6 h-6" />
          </button>
        </div>
      </div>

      {open && (
        <div>
          <button onClick={() => setOpen(!open)}>
            <XIcon className="w-6 h-6" />
          </button>
          <Categories
            category={category}
            categories={categories}
            setCategories={setCategories}
          />
        </div>
      )}

      <PostList />
    </div>
  );
}
