import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useFormik } from "formik";
// import {
//   GET_CATEGORIES,
//   GET_POSTS,
//   NEW_POSTS,
// } from "../../core/constants/apiEndpoints";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useRef } from "react";
import { convertToSlug } from "../../core/helpers/utility";
import { useApi } from "../../core/contexts/ApiProvider";
import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { uploadFile } from "../../core/redux/upload/UploadActions";

export default function PostEditPage() {
  const api = useApi();
  const [post, setPost] = useState();
  const [image, setImage] = useState();
  const [categories, setCategories] = useState([]);

  // const dispatch = useDispatch();
  // const uploadImage = useSelector((state) => state.uploadImage);
  // const { loading, error, image } = uploadImage;

  const tagsRef = useRef();

  const navigate = useNavigate();
  const postId = useParams().id;

  useEffect(async () => {
    (async () => {
      const response = await api.get(`/api/categories`);
      if (response.success) {
        setCategories(response.data);
      }
    })();
  }, []);

  useEffect(async () => {
    if (postId) {
      (async () => {
        const response = await api.get(`/api/posts/byid/${postId}`);
        if (response.success) {
          setPost(response.data);
          setImage(response.data.imageUrl)
        }
      })();
    }
  }, [postId]);

  const handleSubmit = async (values, actions) => {
    values.tags = values.tags.split(",");
    values.slug = convertToSlug(values.title);
    values.imageUrl = image;
    if (!postId) {
      createPost(values, actions);
    } else {
      updatePost(values, actions);
    }
  };

  const handleFilechange = async (file) => {
    let formData = new FormData();
    formData.append("imageMsg", file);
    const response = await api.post(`/api/upload/upload-img`, formData);
    if (response.success) {
      setImage(response.data);
    }
  };

  const createPost = async (values, actions) => {
    const response = await api.post(`/api/posts`, values);
    if (response.success) {
      navigate("/studio/posts");
    }
  };

  const updatePost = async (values, actions) => {
    const response = await api.put(`/api/posts/${postId}`, values);
    if (response.success) {
      navigate("/studio/posts");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      coverPicture: null,
      audioUri: post?.audioUri || "",
      title: post?.title || "",
      slug: post?.title ? convertToSlug(post?.title) : "",
      description: post?.description || "",
      content: post?.content || "",
      tags: post?.tags.join(","),
      isExclusif: post?.isExclusif || false,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().min(3).required("Informations requise"),
      description: Yup.string().min(3).required("Informations requise"),
      tags: Yup.string().required("Informations requise"),
      content: Yup.string().min(3).required("Informations requise"),
    }),
    onSubmit: handleSubmit,
  });

  const handleClickTag = (name) => {
    if (tagsRef.current.value.length === 0) {
      tagsRef.current.value = name;
    } else if (
      tagsRef.current.value.length > 0 &&
      !tagsRef.current.value.includes(name)
    ) {
      tagsRef.current.value = tagsRef.current.value + "," + name;
    }
    formik.setFieldValue("tags", tagsRef.current.value);
  };

  return (
    <div className="max-w-6xl mx-auto sm:px-4 py-4">
      <form onSubmit={formik.handleSubmit} className="p-2 flex space-x-5">
        <div className="w-9/12 flex flex-col space-y-3 h-screen mb-10">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-semibold">Nouvel article</h1>
            <div className="flex items-center space-x-5">
              <Link className="btn-outline" to={`/studio/posts`}>Annuler</Link>

              <button
                className="flex items-center justify-center bg-green-700 text-white rounded-lg px-4 py-2 hover:opacity-70"
                disabled={formik.isSubmitting}
                type="submit"
              >
                {formik.isSubmitting ? "Loading..." : "Enregistrer"}
              </button>
            </div>
          </div>

          <div>
            <input
              className="w-full p-2 focus:ring-1 outline-none rounded-lg bg-white"
              type="text"
              placeholder="Titre"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            {formik.touched.title && formik.errors.title ? (
              <span className="text-red-500">{formik.errors.title}</span>
            ) : null}
          </div>

          <div>
            <label>Tags</label>
            <div className="flex flex-wrap space-x-1">
              {categories &&
                categories.map((c) => (
                  <span
                    key={c._id}
                    className="text-green-700 border-2 font-semibold border-green-700 cursor-pointer my-1 text-xs px-2 py-1 rounded-full hover:bg-green-700 hover:text-white"
                    onClick={() => handleClickTag(c.slug)}
                  >
                    {c.slug}
                  </span>
                ))}
            </div>
            <input
              ref={tagsRef}
              className="w-full p-2 focus:ring-1 outline-none rounded-lg bg-white"
              type="text"
              placeholder="Tags"
              name="tags"
              onChange={formik.handleChange}
              value={formik.values.tags}
            />
            {formik.touched.tags && formik.errors.tags ? (
              <span className="text-red-500">{formik.errors.tags}</span>
            ) : null}
          </div>

          <div>
            <label>Meta description</label>
            <textarea
              className="resize-none w-full focus:ring-1 outline-none rounded-lg font-semibold"
              placeholder="Meta Description"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            ></textarea>
            {formik.touched.description && formik.errors.description ? (
              <span className="text-red-500">{formik.errors.description}</span>
            ) : null}
          </div>

          <div className="flex flex-col flex-1">
            <ReactQuill
              className="mb-10 flex-1 resize-none w-full focus:ring-1 outline-none rounded-lg font-mono tracking-wide text-lg"
              name="content"
              onChange={(e) => formik.setFieldValue("content", e)}
              value={formik.values.content || ""}
            />
            {formik.touched.content && formik.errors.content ? (
              <span className="text-red-500">{formik.errors.content}</span>
            ) : null}
          </div>
        </div>
        <div className="w-1/4 px-4 flex flex-col space-y-4">
          <div>
            <h1 className="text-xl font-semibold text-gray-700 mb-2">
              Image de couverture
            </h1>
            <label className="cursor-pointer" htmlFor="coverPicture">
              <div
                className="border border-dashed border-gray-500 aspect-2 text-gray-500
        flex flex-col items-center justify-center"
              >
                <div>
                  {image && (
                    <img
                      crossOrigin="anonymous"
                      src={`${process.env.REACT_APP_BACKEND_URL}/image/${image}`}
                      alt=""
                      style={{ width: "250px" }}
                    />
                  )}

                  <input
                    style={{ display: "none" }}
                    id="coverPicture"
                    name="coverPicture"
                    type="file"
                    onChange={(e) => handleFilechange(e.currentTarget.files[0])}
                  />
                </div>
              </div>
            </label>
          </div>

          {/* <div>
            <h1 className="text-xl font-semibold text-gray-700 mb-2">
              Fichier audio
            </h1>
            {audioUri && <Player url={audioUri} />}
            {!audioUri && (
              <label className="cursor-pointer" htmlFor="audioUri">
                <input
                  id="audioUri"
                  type="file"
                  accept="audio/*"
                  onChange={(e) =>
                    handleUploadFile(e.target.files[0], "audioUri", "track")
                  }
                  hidden
                />
                <input
                  type="text"
                  hidden
                  name="audioUri"
                  onChange={formik.handleChange}
                  value={formik.values.audioUri}
                />
                <div
                  className="border border-dashed border-gray-500 aspect-2 text-gray-500
            flex flex-col items-center justify-center"
                >
                  <ImHeadphones className="w-10 h-10" />
                  <span className="text-xs">Taille recommandée</span>
                  <span className="text-xs">Moins de 10 Megbytes</span>
                </div>
              </label>
            )}
          </div> */}

          <div>
            <h1 className="text-xl font-semibold text-gray-700 mb-2">
              Disposition
            </h1>
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id="isExclusif"
                name="isExclusif"
                checked={formik.values.isExclusif ? true : false}
                onChange={(e) => {
                  formik.setFieldValue("isExclusif", e.target.checked);
                  console.log(e.target.checked);
                }}
                value={formik.values.isExclusif}
              />
              <label htmlFor="isExclusif">Exclusifs</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
