import React from "react";
import { PaperClipIcon } from "@heroicons/react/outline";

export default function FileViewer({ fileUrl, fileType }) {
  const renderFile = () => {
    if (fileType.startsWith("image/")) {
      return (
        <div className="w-[200px] my-4">
          <img
            src={fileUrl}
            alt="Uploaded content"
            className="w-full rounded-xl"
          />
        </div>
      );
    } else if (fileType.startsWith("video/")) {
      return (
        <div className="w-full my-4">
          <video src={fileUrl} controls className="w-full rounded-xl" />
        </div>
      );
    } else if (fileType.startsWith("audio/")) {
      return (
        <div className="w-full my-4">
          <audio src={fileUrl} controls style={{ maxWidth: "100%" }} />
        </div>
      );
    } else if (fileType === "application/pdf") {
      return (
        <div className="w-full my-4">
          <a href={`${fileUrl}`}>
            <span>Telecharger </span>
            <PaperClipIcon className="w-6 h-6" />
          </a>
        </div>
      );
    }
    // Add more conditions for other file types like Excel files, text files, etc.
    else {
      return <p>File format not supported for preview</p>;
    }
  };
  return <div>{renderFile()}</div>;
}
