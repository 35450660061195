import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import { Provider } from "react-redux";
// import store from "./core/redux/store";
import { BrowserRouter } from "react-router-dom";
import ApiProvider from "./core/contexts/ApiProvider";
import UserProvider from "./core/contexts/UserProvider";
import FlashProvider from "./core/contexts/FlashProvider";
import ModalProvider from "./core/contexts/ModalProvider";
import FlashMessage from "./core/components/FlashMessage";

ReactDOM.render(
  // <Provider store={store}>
    <BrowserRouter>
      <ApiProvider>
        <UserProvider>
          <FlashProvider>
            <ModalProvider>
              <FlashMessage />
              <App />
            </ModalProvider>
          </FlashProvider>
        </UserProvider>
      </ApiProvider>
    </BrowserRouter>,
  // </Provider>,
  document.getElementById("root")
);
