import React, { useState } from "react";
import { useUser } from "../../core/contexts/UserProvider";
import Avatar from "../../core/components/Avatar";
import { ChatIcon, TrashIcon, HeartIcon } from "@heroicons/react/outline";

import { formatTimeAgo } from "../../core/helpers/utility";
import { useApi } from "../../core/contexts/ApiProvider";
import { useFlash } from "../../core/contexts/FlashProvider";
import Comments from "./Comments";
import FileViewer from "../../core/components/FileViewer";

export default function Feed(props) {
  const api = useApi();
  const flash = useFlash();
  const { user } = useUser();

  const checkLike = (likes) => {
    let match = likes.indexOf(user?._id) !== -1;
    return match;
  };

  const [values, setValues] = useState({
    like: checkLike(props.feed.likes),
    likes: props.feed.likes.length,
    comments: props.feed.comments,
  });

  const clickLike = async () => {
    let response;
    if (values.like) {
      response = await api.put(`/api/feeds/unlike/${props?.feed?._id}`);
    } else {
      response = await api.put(`/api/feeds/like/${props?.feed?._id}`);
    }

    if (response.success) {
      setValues({
        ...values,
        like: !values.like,
        likes: response.data.likes.length,
      });
    }
  };

  const updateComments = (comments) => {
    setValues({ ...values, comments: comments });
  };

  const deleteFeed = async () => {
    const response = await api.delete(`/api/feeds/${props?.feed?._id}`);
    if (response.success) {
      props.onRemove(props.feed);
      flash(`Publication supprimee`, "success");
    }
  };

  return (
    <>
      <div className="flex items-start gap-3 w-full my-[32px]">
        <div>
          <Avatar
            img={props.feed?.author?.image?.name}
            text={props.feed?.author?.firstName}
          />
        </div>
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <span className="font-bold">
                {props?.feed?.author?.firstName +
                  " " +
                  props?.feed?.author?.lastName}
              </span>
              <span className="text-gray-400">
                {formatTimeAgo(props?.feed.createdAt)}
              </span>
            </div>
            {user && user._id === props?.feed?.author._id && <button onClick={deleteFeed}>
              <TrashIcon className="w-6 h-6" />
            </button>}
          </div>
          <div>{props?.feed?.text}</div>
          {props?.feed?.file && (
            <FileViewer
              fileUrl={`${process.env.REACT_APP_BACKEND_URL}/file/${props?.feed?.file?.filename}`}
              fileType={props?.feed?.file?.contentType}
            />
          )}
          <div className="flex items-center gap-4 my-4">
            <button className="flex items-center gap-1" onClick={clickLike}>
              {values.like && <HeartIcon className="w-8 h-8 text-blue-500" />}
              {!values.like && <HeartIcon className="w-8 h-8" />}
              <span>{values.likes}</span>
            </button>
            <button className="flex items-center gap-1">
              <ChatIcon className="w-8 h-8" />
              <span>{values.comments.length}</span>
            </button>
          </div>
          <Comments
            feedId={props?.feed._id}
            comments={values?.comments}
            updateComments={updateComments}
          />
        </div>
      </div>
    </>
  );
}
