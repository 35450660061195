import React, { useState } from "react";
import {
  HiOutlineHashtag,
  HiOutlineHome,
  HiOutlineMenu,
  HiOutlinePhotograph,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import logo from "../../logo.jpeg";
import { NavLink } from "react-router-dom";

const links = [
  { name: "Articles", to: "/posts", icon: HiOutlineHome },
  { name: "Videos", to: "/videos", icon: HiOutlinePhotograph },
  // { name: "Livres", to: "/books", icon: HiOutlineUserGroup },
  { name: "Audios", to: "/musics", icon: HiOutlineHashtag },
];

const NavLinks = ({ handleClick }) => (
  <div className="mt-10">
    {links.map((item) => (
      <NavLink
        key={item.name}
        to={item.to}
        className="flex flex-row justify-start items-center my-8 text-sm font-medium text-gray-700 hover:text-gray-700"
        onClick={() => handleClick && handleClick()}
      >
        <item.icon className="w-6 h-6 mr-2" />
        {item.name}
      </NavLink>
    ))}
  </div>
);

export default function MobileMenu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="flex md:hidden">
      <div className="px-4 py-2">
        {!mobileMenuOpen ? (
          <HiOutlineMenu
            className="w-6 h-6 mr-2 text-black"
            onClick={() => setMobileMenuOpen(true)}
          />
        ) : (
          <RiCloseLine
            className="w-6 h-6 mr-2 text-black"
            onClick={() => setMobileMenuOpen(false)}
          />
        )}
      </div>

      <div
        className={`absolute top-0 h-screen w-2/3 bg-gradient-to-tl from-white/10 to-gray-100 backdrop-blur-lg z-40 p-6 md:hidden smooth-transition ${
          mobileMenuOpen ? "left-0" : "-left-full"
        }`}
      >
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "bg-gray-200 flex flex-col items-center px-4 py-2 rounded-lg mb-4"
              : "flex flex-col items-center px-4 py-2 rounded-lg hover:bg-gray-200 mb-4"
          }
          to="/"
        >
          <img
            src={logo}
            alt=""
            className="w-8 h-8 rounded-full object-contain"
            onClick={() => setMobileMenuOpen(false)}
          />
        </NavLink>
        <NavLinks handleClick={() => setMobileMenuOpen(false)} />
      </div>
    </div>
  );
}
