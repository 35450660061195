export const BOOK_CREATE_REQUEST = "BOOK_CREATE_REQUEST";
export const BOOK_CREATE_SUCCESS = "BOOK_CREATE_SUCCESS";
export const BOOK_CREATE_FAILURE = "BOOK_CREATE_FAILURE";

export const BOOK_LIST_REQUEST = "BOOK_LIST_REQUEST";
export const BOOK_LIST_SUCCESS = "BOOK_LIST_SUCCESS";
export const BOOK_LIST_FAILURE = "BOOK_LIST_FAILURE";

export const BOOK_DELETE_REQUEST = "BOOK_DELETE_REQUEST";
export const BOOK_DELETE_SUCCESS = "BOOK_DELETE_SUCCESS";
export const BOOK_DELETE_FAILURE = "BOOK_DELETE_FAILURE";

export const BOOK_UPDATE_REQUEST = "BOOK_UPDATE_REQUEST";
export const BOOK_UPDATE_SUCCESS = "BOOK_UPDATE_SUCCESS";
export const BOOK_UPDATE_FAILURE = "BOOK_UPDATE_FAILURE";

export const BOOK_EDIT_REQUEST = "BOOK_EDIT_REQUEST";
export const BOOK_EDIT_SUCCESS = "BOOK_EDIT_SUCCESS";
export const BOOK_EDIT_FAILURE = "BOOK_EDIT_FAILURE";
