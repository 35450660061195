export const POST_CREATE_REQUEST = "POST_CREATE_REQUEST";
export const POST_CREATE_SUCCESS = "POST_CREATE_SUCCESS";
export const POST_CREATE_FAILURE = "POST_CREATE_FAILURE";

export const POST_UPLOAD_REQUEST = "POST_UPLOAD_REQUEST";
export const POST_UPLOAD_SUCCESS = "POST_UPLOAD_SUCCESS";
export const POST_UPLOAD_FAILURE = "POST_UPLOAD_FAILURE";

export const POST_LIST_REQUEST = "POST_LIST_REQUEST";
export const POST_LIST_SUCCESS = "POST_LIST_SUCCESS";
export const POST_LIST_FAILURE = "POST_LIST_FAILURE";

export const POST_SEARCH_REQUEST = "POST_SEARCH_REQUEST";
export const POST_SEARCH_SUCCESS = "POST_SEARCH_SUCCESS";
export const POST_SEARCH_FAILURE = "POST_SEARCH_FAILURE";

export const POST_DELETE_REQUEST = "POST_DELETE_REQUEST";
export const POST_DELETE_SUCCESS = "POST_DELETE_SUCCESS";
export const POST_DELETE_FAILURE = "POST_DELETE_FAILURE";

export const POST_UPDATE_REQUEST = "POST_UPDATE_REQUEST";
export const POST_UPDATE_SUCCESS = "POST_UPDATE_SUCCESS";
export const POST_UPDATE_FAILURE = "POST_UPDATE_FAILURE";

export const POST_DETAILS_REQUEST = "POST_DETAILS_REQUEST";
export const POST_DETAILS_SUCCESS = "POST_DETAILS_SUCCESS";
export const POST_DETAILS_FAILURE = "POST_DETAILS_FAILURE";
export const POST_UPDATE_LASTMSG = "POST_UPDATE_LASTMSG";
