import React from "react";
import { useModal } from "../../contexts/ModalProvider";
import { XIcon } from "@heroicons/react/outline";

export default function Modal({ children }) {
  const { setClose, isOpen } = useModal();
  return (
    <div
      onClick={setClose}
      className={`
    fixed top-0 left-0 bottom-0 right-0 inset-0 flex justify-center items-center transition-colors
    ${isOpen ? "visible bg-black/20" : "invisible"}
  `}
    >
      {/* modal */}
      <div
        onClick={(e) => e.stopPropagation()}
        className={`
      bg-white rounded-xl shadow p-6 transition-all
      ${isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"}
    `}
      >
        <button
          onClick={setClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
        >
          <XIcon className="w-6 h-6" />
        </button>
        {children}
      </div>
    </div>
  );
}
