import React from "react";
import { Link } from "react-router-dom";
import { formatTimeAgo } from "../../core/helpers/utility";

const PostCard = ({ post }) => {
  console.log(post);
  return (
    <Link
      className="w-full md:w-[250px] cursor-pointer bg-white rounded-md inline-block mr-5 hover:scale-105 transform transition duration-300 ease-out mb-[45px]"
      to={`/posts/${post?.slug}`}
    >
      <div className="relative h-[250px] md:w-[250px]">
        <img
          crossOrigin="anonymous"
          src={`${process.env.REACT_APP_BACKEND_URL}/image/${post.imageUrl}`}
          layout="fill"
          className="h-full w-full absolute inset-0 object-cover rounded-[50px] opacity-100 group-hover:opacity-100"
        />
      </div>
      <div className="mt-[16px]">
        <h1 className="text-xl font-semibold line-clamp-2">{post?.title}</h1>
        <h2 className="text-md text-gray-500 my-1 line-clamp-2">
          {post?.description}
        </h2>
        <div className="text-xs text-gray-500">
          <span>{post?.views} vues . </span>
          <span>{formatTimeAgo(post?.createdAt)}</span>
        </div>
      </div>
    </Link>
  );
};

export default PostCard;
