import React  from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RefreshIcon } from "@heroicons/react/outline";
import { useApi } from "../../core/contexts/ApiProvider";
import { useFlash } from "../../core/contexts/FlashProvider";

// form validation rules
const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Champs requis"),
  lastName: Yup.string().required("Champs requis"),
  telephone: Yup.string().required("Champs requis"),
  email: Yup.string()
    .email("Adresse e-mail invalide")
    .required("Champs requis"),
  password: Yup.string().required("Champs requis"),
});

const RegisterPage = () => {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      code: "+225",
      telephone: "",
      email: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      const response = await api.post("/api/auth/register", values);
      if (response.success) {
        flash(`${response.message}`, "success");
        navigate(`/otp/${response.activationToken}`);
      } else {
        console.log(response);
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <h1 className="text-2xl">S'enregistrer</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-2">
            <label>Nom</label>
            <input
              className="input"
              type="text"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {formik.errors.firstName && formik.touched.firstName ? (
              <div className="text-danger">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="my-2">
            <label>Prénoms</label>
            <input
              className="input"
              type="text"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <span className="text-red-500 text-sm">
                {formik.errors.lastName}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <label>Numéro de téléphone</label>
            <input
              className="input"
              type="text"
              name="telephone"
              value={formik.values.telephone}
              onChange={formik.handleChange}
            />
            {formik.touched.telephone && formik.errors.telephone ? (
              <span className="text-red-500 text-sm">
                {formik.errors.telephone}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <label>Email adresse</label>
            <input
              className="input"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <label>Mot de passe</label>
            <input
              className="input"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {formik.touched.password && formik.errors.password ? (
              <span className="text-red-500 text-sm">
                {formik.errors.password}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <button className="btn" type="submit">
              {formik.isSubmitting ? (
                <RefreshIcon
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                />
              ) : (
                "Enregistrer"
              )}
            </button>
          </div>
        </form>
        <div className="my-4 text-sm font-medium text-green-600 hover:text-green-500">
          <Link to="/login">Déjà un compte ?</Link>
        </div>
        <div className="my-4 text-sm font-medium text-green-600 hover:text-green-500">
          <Link to="/forgotpass">Mot de passe oublier ?</Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
