import React, { useEffect, useState } from "react";
import { useApi } from "../../core/contexts/ApiProvider";
import {
  ArrowNarrowUpIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";

export default function Categories({ categories, setCategories }) {
  const api = useApi();
  // const [categories, setCategories] = useState([]);
  // Holds the list of categories
  const [inputValue, setInputValue] = useState(""); // Holds the current input value for new item
  const [editingId, setEditingId] = useState(null); // ID of the item currently being edited
  const [editingText, setEditingText] = useState(""); // Text of the item currently being edited

  // Add a new item
  const addItem = async () => {
    if (!inputValue.trim()) return; // Prevent adding empty categories
    const response = await api.post(`/api/categories`, { name: inputValue });
    if (response.success) {
      setCategories([...categories, response.data]);
      setInputValue("");
    }
  };

  // Delete an item
  const deleteItem = async (id) => {
    const response = await api.delete(`/api/categories/${id}`);
    if (response.success) {
      setCategories(categories.filter((item) => item._id !== id));
    }
  };

  // Start editing an item
  const editItem = (item) => {
    setEditingId(item._id);
    setEditingText(item.name);
  };

  // Update an item
  const updateItem = async () => {
    const response = await api.put(`/api/categories/${editingId}`, {
      name: editingText,
    });
    console.log(response);
    if (response.success) {
      setCategories(
        categories.map((item) =>
          item._id === editingId ? { ...item, name: editingText } : item
        )
      );
      setEditingId(null);
      setEditingText("");
    }
  };

  const moveUp = () => {};

  return (
    <div>
      <div className="flex items-center gap-4">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="input flex-grow"
        />
        <button className="btn flex-[1]" onClick={addItem}>
          <PlusIcon className="w-6 h-6" />
        </button>
      </div>

      {categories.map((item, index) => (
        <div className="flex items-center gap-4" key={item._id}>
          {editingId === item._id ? (
            <>
              <input
                type="text"
                value={editingText}
                onChange={(e) => setEditingText(e.target.value)}
              />
              <button onClick={updateItem}>Update</button>
            </>
          ) : (
            <>
              <span className="flex-grow px-4 py-2">{item?.name}</span>
              {/* {index !== 0 && (
                <button onClick={() => moveUp(item)}>
                  <ArrowNarrowUpIcon className="w-6 h-6" />
                  {item.section}
                </button>
              )} */}
              <button onClick={() => deleteItem(item._id)}>
                <TrashIcon className="w-6 h-6" />
              </button>
              <button onClick={() => editItem(item)}>
                <PencilIcon className="w-6 h-6" />
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
