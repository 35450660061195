import { useContext } from "react";
import { FlashContext } from "../contexts/FlashProvider";

export default function FlashMessage() {
  const { flashMessage, visible, hideFlash } = useContext(FlashContext);
  return (
    <div className="fixed bottom-10 right-10">
      <div
        className={`${
          flashMessage.type === "success"
            ? "bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md"
            : null
        }`}
        role="alert"
      >
        {visible && (
          <div
            className={`alert alert-${flashMessage.type} flex items-center justify-between`}
            role="alert"
          >
            <span className="text-center font-bold">
              {flashMessage.message}
            </span>
            <button
              type="button"
              className="btn-close justify-end"
              onClick={hideFlash}
            ></button>
          </div>
        )}
      </div>
    </div>
  );
}
