import React from "react";
import { Link } from "react-router-dom";
import PostList from "../../components/studio/PostList";
import ProfileImg from "../../images/profile.png";
import { useUser } from "../../core/contexts/UserProvider";

const ProfilePage = () => {
  const { user } = useUser();
  return (
    <div className="max-w-6xl mx-auto">
      <div className="flex items-center space-x-4 bg-gray-100 p-[40px]">
        <Link to="/profile/avatar">
          {user?.profilePicture === "" ? (
            <img className="h-40 w-40 rounded-full" src={ProfileImg} alt="" />
          ) : (
            <img
              className="h-40 w-40 rounded-full"
              src={user?.profilePicture}
              alt=""
            />
          )}
        </Link>
        <div className="text-center">
          <div className="mb-5">
            <h2 className="text-4xl mb-2">
              {user?.firstName + " " + user?.lastName}
            </h2>
            <p className="text-gray-400 mb-2">{user?.email}</p>
            <p className="text-gray-400 mb-2">{user?.about_me}</p>
          </div>
          <div className="flex items-center justify-center space-x-2 my-2">
            <Link
              className="px-4 py-2 border border-blue-400 text-blue-400 rounded-full"
              to="/profile/update"
            >
              Modifier votre profile
            </Link>
          </div>
        </div>
      </div>
      {/* Mes articles */}
      <PostList />
    </div>
  );
};

export default ProfilePage;
