import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/LoadingError/Loading";
import Message from "../../components/LoadingError/Error";
import PostCard from "../../components/Posts/PostCard";
import Pagination from "../../core/components/common/Pagination";
import EmptyList from "../../components/EmptyList";

import { useApi } from "../../core/contexts/ApiProvider";
import { useUser } from "../../core/contexts/UserProvider";

const PostPage = () => {
  const api = useApi();
  const navigate = useNavigate();
  const query = useLocation().search;

  const { user } = useUser();
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const onCategorychange = (selected) => {
    setCategory(selected);
    navigate(`/posts?tags=${selected.slug}`);
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/posts${query}`);
      if (response.success) {
        setPosts(response.posts);
        setPage(response.page);
        setPages(response.pages);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/categories`);
      if (response.success) {
        setCategories(response.data);
      }
    })();
  }, []);

  return (
    <div className="relative bg-white px-4">
      <div className="max-w-6xl mx-auto">
        <div className="flex space-x-10">
          <div className="flex-[3]">
            <div className="py-4 px-4 flex items-center justify-between sticky top-0 z-10 bg-white">
              <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 py-6">
                Articles
              </h1>
              <div className="flex items-center">
                {user?.isAdmin && (
                  <Link
                    className="text-gray-400 bg-black hover:bg-gray-600 px-4 py-2 rounded shadow-sm"
                    to={`/posts/new`}
                  >
                    Créer un article
                  </Link>
                )}
                <select
                  onChange={(e) => onCategorychange(e.target.value)}
                  value={category}
                  className="flex md:hidden bg-black text-gray-300 p-3 text-sm rounded-lg outline-none"
                >
                  <option value="">Toutes catégories</option>
                  {categories?.map((category) => (
                    <option key={category._id} value={category.slug}>
                      {category.slug}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {loading ? (
              <div className="mb-5">
                <Loading />
              </div>
            ) : (
              <>
                {posts.length > 0 ? (
                  <div className="my-[40px]">
                    <div className="px-4 grid grid-cols-1 gap-x-4 my-5 md:grid-cols-2 lg:grid-cols-3">
                      {posts?.map((v) => (
                        <PostCard key={v._id} post={v} />
                      ))}
                    </div>
                    <div>
                      {/* Pagination */}
                      <Pagination
                        route="/posts"
                        page={page}
                        pages={pages}
                        keyword={keyword ? keyword : ""}
                      />
                    </div>
                  </div>
                ) : (
                  <EmptyList title="Aucuns articles" />
                )}
              </>
            )}
          </div>
          <div className="hidden md:flex flex-[1] h-screen sticky top-0">
            <div className="w-full h-full">
              <div className="py-4 px-4 flex items-center justify-between z-30 bg-white">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 py-6">
                  Catégories
                </h1>
              </div>
              <div className="py-4 px-4">
                <div className="flex flex-wrap">
                  <Link
                    to={`/posts`}
                    className="bg-gray-100 m-1 p-2 text-sm rounded-full font-semibold text-green-600 hover:text-green-800"
                  >
                    Toutes catégories
                  </Link>
                </div>
                {categories &&
                  categories
                    ?.sort((a, b) => a.section - b.section)
                    .map((c) => (
                      <div className="flex flex-wrap">
                        <Link
                          key={c._id}
                          to={`/posts?tags=${c.slug}`}
                          className="bg-gray-100 m-1 p-2 text-sm rounded-full font-semibold text-green-600 hover:text-green-800"
                        >
                          {c.name}
                        </Link>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage;
