export const IMAGE_CREATE_REQUEST = "IMAGE_CREATE_REQUEST";
export const IMAGE_CREATE_SUCCESS = "IMAGE_CREATE_SUCCESS";
export const IMAGE_CREATE_FAILURE = "IMAGE_CREATE_FAILURE";

export const IMAGE_LIST_REQUEST = "IMAGE_LIST_REQUEST";
export const IMAGE_LIST_SUCCESS = "IMAGE_LIST_SUCCESS";
export const IMAGE_LIST_FAILURE = "IMAGE_LIST_FAILURE";

export const IMAGE_DELETE_REQUEST = "IMAGE_DELETE_REQUEST";
export const IMAGE_DELETE_SUCCESS = "IMAGE_DELETE_SUCCESS";
export const IMAGE_DELETE_FAILURE = "IMAGE_DELETE_FAILURE";

export const IMAGE_UPDATE_REQUEST = "IMAGE_UPDATE_REQUEST";
export const IMAGE_UPDATE_SUCCESS = "IMAGE_UPDATE_SUCCESS";
export const IMAGE_UPDATE_FAILURE = "IMAGE_UPDATE_FAILURE";

export const IMAGE_EDIT_REQUEST = "IMAGE_EDIT_REQUEST";
export const IMAGE_EDIT_SUCCESS = "IMAGE_EDIT_SUCCESS";
export const IMAGE_EDIT_FAILURE = "IMAGE_EDIT_FAILURE";
