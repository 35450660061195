import React, { useEffect, useState } from "react";
import { useApi } from "../../core/contexts/ApiProvider";
import NewFeed from "./NewFeed";
import FeedList from "./FeedList";
import { useUser } from "../../core/contexts/UserProvider";

export default function FeedPage() {
  const { user } = useUser();
  const api = useApi();
  const [feeds, setFeeds] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/feeds`);
      if (response) {
        console.log(response);
        setFeeds(response.feeds);
        setPages(response.pages);
        setPage(response.page);
      }
    })();
  }, []);

  const addFeed = (feed) => {
    setFeeds((oldFeeds) => [...oldFeeds, feed]);
  };

  const removeFeed = (feed) => {
    setFeeds((prev) => [...prev.filter((item) => item._id !== feed._id)]);
  };

  return (
    <div className="relative bg-white overflow-hidden px-4">
      <div className="max-w-2xl mx-auto md:px-4 py-4">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 py-6">
          Actualites
        </h1>
        {user && user?.isAdmin ? <NewFeed addUpdate={addFeed} /> : null}
        <FeedList removeUpdate={removeFeed} feeds={feeds} />
      </div>
    </div>
  );
}
