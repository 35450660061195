import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { RefreshIcon } from "@heroicons/react/outline";
import { useFlash } from "../../core/contexts/FlashProvider";
import { useApi } from "../../core/contexts/ApiProvider";

// form validation rules
const ForgotPassSchema = Yup.object().shape({
  email: Yup.string()
    .email("Adresse e-mail invalide")
    .required("Champs requis"),
});

const ForgotPassPage = () => {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPassSchema,
    onSubmit: async (values) => {
      const response = await api.post(`/api/auth/forgot-password`, values);
      if (response.success) {
        navigate(`/verify/${response.activationToken}`);
      } else {
        flash(`${response.error.message}`, "danger");
      }
    },
  });

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full space-y-8">
        <h1 className="text-2xl">Aide avec le mot de passe</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="my-2">
            <label>Email adresse</label>
            <input
              className="input"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {formik.touched.email && formik.errors.email ? (
              <span className="text-red-500 text-sm">
                {formik.errors.email}
              </span>
            ) : null}
          </div>
          <div className="my-2">
            <button className="btn" type="submit">
              {formik.isSubmitting ? (
                <RefreshIcon
                  className="animate-spin h-5 w-5 mr-3 ..."
                  viewBox="0 0 24 24"
                />
              ) : (
                "Continuer"
              )}
            </button>
          </div>
        </form>
        <div className="my-4 text-sm font-medium text-green-600 hover:text-green-500">
          <Link to="/register">Pas de compte ?</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassPage;
