export const VIDEO_CREATE_REQUEST = "VIDEO_CREATE_REQUEST";
export const VIDEO_CREATE_SUCCESS = "VIDEO_CREATE_SUCCESS";
export const VIDEO_CREATE_FAILURE = "VIDEO_CREATE_FAILURE";

export const VIDEO_LIST_REQUEST = "VIDEO_LIST_REQUEST";
export const VIDEO_LIST_SUCCESS = "VIDEO_LIST_SUCCESS";
export const VIDEO_LIST_FAILURE = "VIDEO_LIST_FAILURE";

export const VIDEO_DELETE_REQUEST = "VIDEO_DELETE_REQUEST";
export const VIDEO_DELETE_SUCCESS = "VIDEO_DELETE_SUCCESS";
export const VIDEO_DELETE_FAILURE = "VIDEO_DELETE_FAILURE";

export const VIDEO_UPDATE_REQUEST = "VIDEO_UPDATE_REQUEST";
export const VIDEO_UPDATE_SUCCESS = "VIDEO_UPDATE_SUCCESS";
export const VIDEO_UPDATE_FAILURE = "VIDEO_UPDATE_FAILURE";

export const VIDEO_EDIT_REQUEST = "VIDEO_EDIT_REQUEST";
export const VIDEO_EDIT_SUCCESS = "VIDEO_EDIT_SUCCESS";
export const VIDEO_EDIT_FAILURE = "VIDEO_EDIT_FAILURE";
