import React, { useRef, useState } from "react";
import { useUser } from "../../core/contexts/UserProvider";
import Avatar from "../../core/components/Avatar";
import {
  CameraIcon,
  DocumentIcon,
  FilmIcon,
  MusicNoteIcon,
  PhotographIcon,
  PaperClipIcon
} from "@heroicons/react/outline";
import Gallery from "../../core/components/Gallery";
import Modal from "../../core/components/common/Modal";
import { useModal } from "../../core/contexts/ModalProvider";
import { useApi } from "../../core/contexts/ApiProvider";
import Spinner from "../../core/components/Spinner";
import { useFlash } from "../../core/contexts/FlashProvider";
import FileViewer from "../../core/components/FileViewer";

export default function NewFeed(props) {
  const api = useApi();
  const flash = useFlash();
  const { user } = useUser();
  const [text, setText] = useState("");
  const inputLinkRef = useRef(null);
  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const { setOpen } = useModal();

  const handleChange = async (event) => {
    setIsImgLoading(true);
    const file = event?.target?.files[0];
    let formData = new FormData();
    formData.append("file", file);
    const response = await api.post(`/api/upload`, formData);
    if (response.success) {
      setFile(response.data);
      setIsImgLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await api.post(`/api/feeds`, { file, text });
    if (response.success) {
      setFile(null);
      setText("");
      setIsLoading(false);
      props.addUpdate(response.feed);
      flash(`Nouvelle publication`, "success");
    }
  };

  return (
    <div className="flex align-items-start gap-3 w-full">
      <div>
        <Avatar img={user?.image?.name} text={user?.firstName} />
      </div>
      <div className="flex-grow">
        {file && (
          <FileViewer
            fileUrl={`${process.env.REACT_APP_BACKEND_URL}/file/${file.filename}`}
            fileType={file.contentType}
          />
        )}
        {isImgLoading && (
          <div className="my-2">
            <Spinner />
          </div>
        )}
        <input
          type="text"
          className="w-full h-20 rounded"
          placeholder="Quoi de neuf ?"
          rows="3"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <div className="flex items-center justify-between py-4">
          <div className="flex items-center gap-3">
            <button
              onClick={() => {
                setOpen(
                  <Modal>
                    <Gallery file={file} setFile={setFile} />
                  </Modal>
                );
              }}
            >
              <PhotographIcon className="w-6 h-6" />
            </button>
            <button>
              <label htmlFor="icon-file">
                <input
                  onChange={(e) => handleChange(e)}
                  id="icon-file"
                  type="file"
                  style={{ display: "none" }}
                />
                <CameraIcon className="w-6 h-6" />
              </label>
            </button>
            <button>
              <label htmlFor="icon-file">
                <input
                  onChange={(e) => handleChange(e)}
                  id="icon-file"
                  type="file"
                  style={{ display: "none" }}
                />
                <PaperClipIcon className="w-6 h-6" />
              </label>
            </button>
          </div>
          <div className="flex justify-between">
            <button disabled={isLoading} onClick={handleSubmit} className="btn">
              Publier
              {isLoading && <Spinner />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
